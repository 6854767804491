import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      order: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      order
    }) {
      this.order = order;
    }
  },
  computed: {
    url() {
      const apiBaseURL = this.$api.defaults.baseURL;
      return `${apiBaseURL}/billing/invoice/${this.order.code}`;
    }
  },
  components: {
    Modal
  }
};