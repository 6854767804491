var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Счет успешно оформлен",
      "width": 600
    }
  }, [_vm.order ? [_c('div', [_vm._v(" Счет №" + _vm._s(_vm.order.id) + " успешно оформлен."), _c('br'), _vm._v("Сумма к оплате: "), _c('strong', [_vm._v(_vm._s(_vm.order.sum) + " руб")]), _c('div', {
    staticClass: "mt16"
  }, [_c('UiButton', {
    attrs: {
      "variant": "blue",
      "href": _vm.url,
      "download": "",
      "component": "a"
    }
  }, [_vm._v(" Скачать счет ")])], 1)])] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };